/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import styles from './TaskList.module.css';
import { ReactComponent as Close } from '../ico/close.svg';
import { Button } from '../../UI/Button/Button';
import CurrentClient from '../../../api/workDay/dto/GetCurrentClientResp';
import { observer } from 'mobx-react';
import { useStores } from '../../../hooks/useStore';
import { useApi } from '../../../hooks/useApi';
import { useEffect, useState } from 'react';
import { formatDateForTask, isDateExpired } from '../../../utils/date';

interface PopupProps {
  onClose: () => void;
	setClient: (id: string) => void;
}

const TaskListPopUP: React.FC<PopupProps> = observer(({ onClose, setClient }) => {

	const store = useStores();
	const api = useApi();

	const handleOverlayClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		// Проверяем, что клик произошел именно на overlay, а не на его содержимое
		if (event.target === event.currentTarget) {
			onClose();
		}
	};

	const [taskList, setTaskList] = useState<CurrentClient[]>([]);


	const handleButtonClick = async (id: string) => {
		try {
			store.notificationsStore.showPreloader();
			setClient(id);
		} catch (e) {
			store.notificationsStore.setError('Ошибка выбора задачи');
		} finally {
			store.notificationsStore.hidePreloader();
			store.notificationsStore.setNotifications('Задача выбрана');
			setClient(id);
			onClose();
		}
	}

	useEffect(() => {
		getTaskList();
	}, []);

	const [total, setTotal] = useState(0);
	// const [page, setPage] = useState(0);
	const [maxPage, setMaxPage] = useState(0);
	const [rows, setRows] = useState(10);

	useEffect(() => {
		getTaskList();
	}, [store.task.page, rows]);

	const getTaskList = async () => {
		try {
			store.notificationsStore.showPreloader();

			if (store?.userStore?.user?.axl_id) {
				const {body} = await api.workDay.getTaskList(store?.userStore?.user?.axl_id, store.task.page * rows, rows);
				if (body) {
					setTaskList(body.list);
					setTotal(body.total);
					setMaxPage(Math.ceil(body.total / rows));
				}
			}

		} catch (e) {
			store.notificationsStore.setError('Загрузка задач не удалась');
		} finally {
			store.notificationsStore.hidePreloader();
		}
	}
	
	return (
		<>
			<div className={styles.container} onClick={handleOverlayClick}>
				<div className={styles.form}>
					<Close className={styles.close} onClick={onClose}/>
					<h3>Список задач</h3>
					<div className={styles.line} style={{marginBottom: 10}}/>

					{taskList && taskList.map((task, index) => (
						<>
							<div key={task.task.id} className={styles.task}>

								<div className={styles.taskClientTitleContainer}>
									<p style={{fontSize: 14}}>{task.client.name}</p>
									<p style={{fontSize: 14}}>{task.task.title}</p>
									<p style={{fontSize: 14, color: !isDateExpired(task.task.date_start) ? 'red' : 'black'}}>{formatDateForTask(task.task.date_start)}</p>
								</div>

								<div className={styles.taskStageTagsContainer}>
									<div style={{display: 'flex', alignItems: 'center'}}> 
										<div style={{marginRight: 5, width: 10, height: 10, borderRadius: 100, background: task.deal?.stage_color}}/>
										<p style={{fontSize: 12}}>{task.deal?.stage_name}</p>
									</div>

									{task.deal?.tags &&
								<div className={styles.taskTagsContainer}>
									{task.deal?.tags.map((tag) => ( 
										<div key={tag.id} style={{background: tag.color, margin: 2, padding: 5, borderRadius: 4, width: 'max-content'}}>
											<p style={{fontSize: 10, color: 'white'}}>{tag.name}</p>
										</div>
									))}
								</div>
									}

								</div>

								<Button style={{width: 100, height: 35}} appearance='link' onClick={() => handleButtonClick(task.task.id)}>Выбрать</Button>
							</div>
							{index + 1 !== taskList.length && <div className={styles.line} style={{marginBottom: 10}}/>}
						</>
					))}

					<div className={styles.line} style={{marginBottom: 0, marginTop: 20}}/>
					<p style={{marginTop: 20, marginBottom: 0}}>Страница {store.task.page + 1} из {maxPage}</p>
					<div className={styles.pagination}>
						{ store.task.page !== 0 && <Button style={{width: 150, margin: 5}} appearance='link' onClick={() => store.task.setPage(store.task.page - 1)}>Предыдущая</Button>}
						{ store.task.page + 1 !== maxPage && <Button style={{width: 150, margin: 5}} appearance='link' onClick={() => store.task.setPage(store.task.page + 1)}>Следующая</Button>}
					</div>
					{taskList.length === 0 && <div
						style={{textAlign: 'center', marginTop: 10, fontSize: 16, color: '#00000066'}}
					>
						Задачи не найдены
					</div>
					}
				</div>
			</div>
		</>
	);
});

export default TaskListPopUP;
