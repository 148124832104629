import styles from './Realized.module.css';
import { ReactComponent as Close } from '../ico/close.svg';
import { Button } from '../../UI/Button/Button';
import CurrentClient from '../../../api/workDay/dto/GetCurrentClientResp';
import { observer } from 'mobx-react';
import { useStores } from '../../../hooks/useStore';
import { useApi } from '../../../hooks/useApi';

interface PopupProps {
  client: CurrentClient;
  onClose: () => void;
	updateClient: () => void;
}

const RealizedPopUP: React.FC<PopupProps> = observer(({ client, onClose, updateClient }) => {

	const store = useStores();
	const api = useApi();

	const handleOverlayClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		// Проверяем, что клик произошел именно на overlay, а не на его содержимое
		if (event.target === event.currentTarget) {
			onClose();
		}
	};

	const handleButtonClick = async () => {
		try {
			store.notificationsStore.showPreloader();

			await api.workDay.realizedClient({
				deal_id: client?.deal?.id ? client?.deal.id : '',
				stage_id: client?.pipeline?.realization_stage_id ? client?.pipeline.realization_stage_id : '',
				task_id: client.task.id,
			});

		} catch (e) {
			store.notificationsStore.setError('Ошибка обработки клиента');
		} finally {
			updateClient();
			store.notificationsStore.hidePreloader();
			store.notificationsStore.setNotifications('Клиент обработан');
			onClose();
		}
	}
	
	return (
		<>
			<div className={styles.container} onClick={handleOverlayClick}>
				<div className={styles.form}>
					<Close className={styles.close} onClick={onClose}/>
					<h3>Уверены, что<br/>сделка реализована?</h3>
					<div className={styles.line} style={{marginBottom: 10}}/>
					<div className={styles.buttons}>
						<Button appearance='auth' onClick={onClose} className={styles.button} style={{marginRight: 10}}>Отмена</Button>
						<Button appearance='link' onClick={handleButtonClick} className={styles.button}>Да</Button>
					</div>
				</div>
			</div>

		</>
	);
});

export default RealizedPopUP;
