/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import styles from './Complete.module.css';
import { ReactComponent as Close } from '../ico/close.svg';
import { Button } from '../../UI/Button/Button';
import CurrentClient from '../../../api/workDay/dto/GetCurrentClientResp';
import { observer } from 'mobx-react';
import { useStores } from '../../../hooks/useStore';
import { useApi } from '../../../hooks/useApi';
import { Input } from '../../UI/input/input';
import DatePickerSingle from '../../UI/DatePicker/datePickerSingle';
import { useEffect, useState } from 'react';
import StagesPipeline from '../../../api/workDay/dto/StagesPipeline';

interface PopupProps {
  client: CurrentClient;
  onClose: () => void;
	updateClient: () => void;
}

const CompletePopUP: React.FC<PopupProps> = observer(({ client, onClose, updateClient }) => {

	const store = useStores();
	const api = useApi();

	const [stages, setStages] = useState<StagesPipeline[]>([]);
	const [selectedStage, setSelectedStage] = useState<string>(client?.deal?.stage_id ? client?.deal?.stage_id : '');

	const [result, setResult] = useState<string>('');
	const [newTask, setNewTask] = useState<string>('');

	useEffect(() => {
		(async () => {
			try {
				const data = await api.workDay.getStagesPipeline(client?.pipeline?.id ? client?.pipeline?.id : '');
				if (data.body) {
					setStages(data.body);
				}
			} catch (e) {
				store.notificationsStore.setError('Ошибка загрузки этапов воронки');
			}
		})();

	}, [client]);

	const handleOverlayClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		// Проверяем, что клик произошел именно на overlay, а не на его содержимое
		if (event.target === event.currentTarget) {
			onClose();
		}
	};

	const handleButtonClick = async () => {
		try {
			store.notificationsStore.showPreloader();

			const dates = getStartAndEndDate(dateStart);

			await api.workDay.completeClientReq({
				deal_id: client?.deal?.id ? client?.deal?.id : '',
				responsible_admin_id: store.userStore.user?.axl_id || '',
				client_id: client.client.id,
				stage_id: selectedStage,
				task_id: client.task.id,
				task_comment: result,
				new_task: {
					title: newTask,
					begin_date: dates.start_date,
					end_date: dates.end_date
				}
			});

		} catch (e) {
			store.notificationsStore.setError('Ошибка обработки клиента');
		} finally {
			updateClient();
			store.notificationsStore.hidePreloader();
			store.notificationsStore.setNotifications('Клиент обработан');
			onClose();
		}
	}

	const getCurrentTime = (offset: number) => {
		const date = new Date();
		const utc = date.getTime()
		const newDate = new Date(utc + (3600000*offset));
		return newDate;
	}

	const [dateStart, setDateStart] = useState(getCurrentTime(1).toISOString());

	const selectStage = (id: string) => {
		setSelectedStage(id);
	}

	const getStartAndEndDate = (dateString: string) => {
		const startDate = new Date(dateString);
    
		// Приводим к нужному формату для start_date
		const formattedStartDate = startDate.toISOString().split('.')[0] + '.000Z';
    
		// Создаем end_date, добавляя 15 минут к start_date
		const endDate = new Date(startDate.getTime() + 15 * 60000);
		const formattedEndDate = endDate.toISOString().split('.')[0] + '.000Z';
    
		return {
			start_date: formattedStartDate,
			end_date: formattedEndDate,
		};
	}
	
	return (
		<>
			<div className={styles.container} onClick={handleOverlayClick}>
				<div className={styles.form}>
					<Close className={styles.close} onClick={onClose}/>
					<h3>Выбери новый этап и задачу</h3>
					<div className={styles.line} style={{marginBottom: 10}}/>

					<Input style={{maxWidth: '90%'}} placeholder='Результат закрытия задачи' textLabel='Результат закрытия задачи' onChange={(event) => setResult(event.target.value)}/>

					<Input style={{maxWidth: '90%'}} placeholder='Текст новой задачи' textLabel='Текст новой задачи' onChange={(event) => setNewTask(event.target.value)}/>

					<div style={{width: '90%', maxWidth: '90%', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
						<p>Выбери время начала задачи</p>
						<DatePickerSingle value={dateStart} defaultValue={dateStart} onChangeDate={setDateStart}/>
					</div>

					<div style={{display: 'flex', flexWrap: 'wrap', width: '90%', marginTop: 20, marginBottom: 20, justifyContent: 'center'}}>
						<Button style={{margin: 5,  width: 150, height: 40}} appearance='link' onClick={() => setDateStart(getCurrentTime(3).toISOString())}>Через 3 часа</Button>
						<Button style={{margin: 5, width: 150, height: 40}} appearance='link' onClick={() => setDateStart(getCurrentTime(6).toISOString())}>Через 6 часов</Button>
						<Button style={{margin: 5, width: 150, height: 40}} appearance='link' onClick={() => setDateStart(getCurrentTime(12).toISOString())}>Через 12 часов</Button>
						<Button style={{margin: 5, width: 150, height: 40,}} appearance='link'onClick={() => setDateStart(getCurrentTime(24).toISOString())} >Через 1 день</Button>
						<Button style={{margin: 5, width: 150, height: 40}} appearance='link' onClick={() => setDateStart(getCurrentTime(72).toISOString())}>Через 3 дня</Button>
						<Button style={{margin: 5, width: 150, height: 40}} appearance='link' onClick={() => setDateStart(getCurrentTime(168).toISOString())}>Через 7 дней</Button>
					</div>

					<div className={styles.line} style={{marginBottom: 10}}/>

					{/* Вот тут поле где появляется список этапов StagesPipeline на выбор, из названия и круга перед названием выбраного цвета */}

					<p>Выбери новый этап сделки:</p>

					<div style={{display: 'flex', flexWrap: 'wrap', width: '90%', alignItems: 'center', marginTop: 10, marginBottom: 20}}>

						{stages.map((stage, index) => (
							<div onClick={() => selectStage(stage.id)} style={{display: 'flex', alignItems: 'center', padding: 15, borderColor: '#7a76ff', borderRadius: 10, borderStyle: 'solid', borderWidth: 1, width: 'max-content', margin: 5, background: selectedStage === stage.id ? '#7a76ff' : 'transparent', cursor: selectedStage === stage.id ? 'default' : 'pointer'}}>
								<div style={{width: 10, height: 10, borderRadius: 100, background: selectedStage === stage.id ? 'white' : stage.color, marginRight: 5}}/>
								<div style={{color: selectedStage === stage.id ? 'white' : 'black'}}>{stage.name}</div>
							</div>
						))}

					</div>

					<div className={styles.line} style={{marginBottom: 10}}/>

					<Button onClick={handleButtonClick} style={{margin: 5, maxWidth: 320, marginBottom: 25}} appearance='auth'>Сохранить</Button>

				</div>
			</div>
		</>
	);
});

export default CompletePopUP;
