import { makeAutoObservable, runInAction } from 'mobx';
import MainStore from './mainStore';

export default class TaskStore {
	private mainStore: MainStore;

	page: number = 0;


	constructor(mainStore: MainStore) {
		makeAutoObservable(this);

		this.mainStore = mainStore;
	}

	setPage = (page: number) => {
		runInAction(() => {
			this.page = page;
		});
	}
}