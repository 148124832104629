import { HeaderProps } from './Header.props';
import styles from './Header.module.css';
import { ReactComponent as Logo } from './nan.svg';
// import DatePickers from '../../UI/DatePicker/datePicker';

export const Header = ({...props}: HeaderProps): JSX.Element => {

	return (
		<div {...props}>
			<Logo className={styles.logo}/>

			<div className={styles.datesSelect}>

				{/* <DatePickers/> */}
			</div>
		</div>
	);
};