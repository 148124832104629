import React, { useEffect } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import styles from './Sidebar.module.css';
import cn from 'classnames';
import { ReactComponent as Exit } from './ico/exit.svg';
import { ReactComponent as AutotrackingIco } from './ico/autotracking.svg';
import Avatar from '../../UI/Avatar/Avatar';
import { useStores } from '../../../hooks/useStore';
import { observer } from 'mobx-react';

interface SidebarProps {
  className?: string;
}

export const Sidebar: React.FC<SidebarProps> = observer((props) => {
	const navigate = useNavigate();
	const {userStore, authStore} = useStores();

	const paths = {
		work: '/work',
		profile: '/profile',
	};

	const [name, setName] = React.useState<string>('');
	const [email, setEmail] = React.useState<string>('');

	useEffect(() => {
		if (userStore.user?.name && userStore.user?.email) {
			setName(userStore?.user?.name.length >= 15 ? `${userStore?.user?.name.slice(0, 15)}...` : userStore.user.name)
			setEmail(userStore?.user?.email?.length >= 19 ? `${userStore.user.email.slice(0, 18)}...` : userStore.user.email)
		}

	}, [userStore.user]);


	const exit = async () => {
		await authStore.logout();
		navigate('/');
	};

	const setActiveLink = (isActive: boolean) => isActive ? cn(styles.menuItem, styles.active) : styles.menuItem;

	return (
		<div {...props}>

			<div className={styles.menu}>



				<NavLink to={paths.work} className={({isActive}) => setActiveLink(isActive)}>
					<div className={styles.line}/>
					<AutotrackingIco/>
					<p>Рабочий день</p>
				</NavLink>
				


			</div>

			<div className={styles.menuDown}>

				<Link to='/profile' className={styles.profile}>
					<Avatar size='48px' setting={true}/>
					<div>
						<p className={styles.name}>{name}</p>
						<p className={styles.email}>{email}</p>
					</div>
				</Link>

				<div className={styles.exit} onClick={exit}>
					<Exit/>
					<p>Выход</p>
				</div>

			</div>
		</div>
	);
});