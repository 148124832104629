import styles from './DeleteOrder.module.css';
import { ReactComponent as Close } from '../ico/close.svg';
import { Button } from '../../UI/Button/Button';
import { observer } from 'mobx-react';
import { useStores } from '../../../hooks/useStore';
import { useApi } from '../../../hooks/useApi';

interface PopupProps {
  orderId: string;
	resetDeleteOrderId: () => void;
  onClose: () => void;
	updateClient: () => void;
}

const DeleteOrderPopUP: React.FC<PopupProps> = observer(({ orderId, resetDeleteOrderId, onClose, updateClient }) => {

	const store = useStores();
	const api = useApi();

	const handleOverlayClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		// Проверяем, что клик произошел именно на overlay, а не на его содержимое
		if (event.target === event.currentTarget) {
			onClose();
			resetDeleteOrderId();	
		}
	};

	const handleButtonClick = async () => {
		try {
			store.notificationsStore.showPreloader();

			await api.workDay.deleteOrder(orderId);

		} catch (e) {
			store.notificationsStore.setError('Ошибка удаления заказа');
		} finally {
			updateClient();
			resetDeleteOrderId();
			store.notificationsStore.hidePreloader();
			store.notificationsStore.setNotifications('Заказ удален');
			onClose();
		}
	}
	
	return (
		<>
			<div className={styles.container} onClick={handleOverlayClick}>
				<div className={styles.form}>
					<Close className={styles.close} onClick={onClose}/>
					<h3>Уверены, что хотите<br/>удалить заказ?</h3>
					<div className={styles.line} style={{marginBottom: 10}}/>
					<div className={styles.buttons}>
						<Button appearance='auth' onClick={onClose} className={styles.button} style={{marginRight: 10}}>Отмена</Button>
						<Button appearance='link' onClick={handleButtonClick} className={styles.button}>Да</Button>
					</div>
				</div>
			</div>

		</>
	);
});

export default DeleteOrderPopUP;
