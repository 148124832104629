/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import styles from './CreateOrder.module.css';
import { ReactComponent as Close } from '../ico/close.svg';
import { Button } from '../../UI/Button/Button';
import CurrentClient from '../../../api/workDay/dto/GetCurrentClientResp';
import { observer } from 'mobx-react';
import { useStores } from '../../../hooks/useStore';
import { useApi } from '../../../hooks/useApi';
import { Input } from '../../UI/input/input';
import DatePickerSingle from '../../UI/DatePicker/datePickerSingle';
import { useEffect, useState } from 'react';
import StagesPipeline from '../../../api/workDay/dto/StagesPipeline';
import ProductList from '../../../api/workDay/dto/ProductList';
import { AppSettings } from '../../../appSettings';
import { CreateOrderAxlInfo } from '../../../api/workDay/dto/CreateOrder';

interface PopupProps {
  client: CurrentClient;
  onClose: () => void;
	updateClient: () => void;
}

const CreateOrderPopUP: React.FC<PopupProps> = observer(({ client, onClose, updateClient }) => {

	const store = useStores();
	const api = useApi();

	const [searchText, setSearchText] = useState<string>('');

	const [products, setProducts] = useState<ProductList[]>([]);
	const [selectsProducts, setSelectsProducts] = useState<ProductList[]>([]);

	const [paymentUrl, setPaymentUrl] = useState<string>('');

	const getProducts = async () => {
		try {
			const {body} = await api.workDay.getProductList(searchText);
			if (body) {
				setProducts(body);
			}
		} catch (e) {
			store.notificationsStore.setError('Ошибка загрузки продуктов');
		}
	}

	useEffect(() => {
		if (searchText.length > 2) {
			getProducts();
		}
	}, [searchText]);

	const handleOverlayClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		// Проверяем, что клик произошел именно на overlay, а не на его содержимое
		if (event.target === event.currentTarget) {
			onClose();
		}
	};

	const handleButtonClick = async () => {
		try {
			store.notificationsStore.showPreloader();

			const endDate = getEndDate();

			const infoOrder: CreateOrderAxlInfo = {
				status: 'created',
				studentId: client.client.id,
				responsibleId: store.userStore.user?.axl_id || '',
				pipelineItemId: client?.deal?.id ? client?.deal?.id : '', 
				expirationDate: endDate,
				shippingFirstName: client.client.first_name,
				shippingEmail: client.client.email
			}

			if (client.client.phone && client.client.phone !== '') {
				infoOrder.shippingPhone = client.client.phone;
			}

			if (client.client.last_name && client.client.last_name !== '') {
				infoOrder.shippingLastName = client.client.last_name;
			}

			const {body} = await api.workDay.createOrder({
				info: infoOrder,
				contents: selectsProducts.map((product) => ({
					productId: product.id,
					basePrice: product.price,
					discount: 0,
					quantity: 1
				})),
				fieldValues: []
			});

			if (body) {
				setPaymentUrl(AppSettings.api.paymentUrl + body);
			}

		} catch (e) {
			store.notificationsStore.setError('Ошибка создания заказа');
		} finally {
			store.notificationsStore.hidePreloader();
			store.notificationsStore.setNotifications('Заказ создан');
			updateClient();
			onClose();
		}
	}

	const getCurrentTime = (offset: number) => {
		const date = new Date();
		const utc = date.getTime()
		const newDate = new Date(utc + (3600000*offset));
		return newDate;
	}

	const getEndDate = () => {
		const startDate = new Date(getCurrentTime(168).toISOString());
    
		// Приводим к нужному формату для start_date
		const formattedStartDate = startDate.toISOString().split('.')[0] + '.000Z';
    
		return formattedStartDate;
	}

	const selectProduct = (product: ProductList) => {
		setSelectsProducts([...selectsProducts, product]);
		setSearchText('');
	}

	const copyPaymentUrl = () => {
		navigator.clipboard.writeText(paymentUrl);
		store.notificationsStore.setNotifications('Ссылка скопирована');
	}
	
	return (
		<>
			<div className={styles.container} onClick={handleOverlayClick}>
				<div className={styles.form}>
					<Close className={styles.close} onClick={onClose}/>
					<h3>Создание заказа</h3>
					<div className={styles.line} style={{marginBottom: 10}}/>

					{paymentUrl !== '' &&

					<div style={{width: '100%', padding: 20, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>

						<p style={{marginBottom: 20, marginTop: 10, width: '100%'}}>Ссылка на оплату:</p>
						<p style={{marginBottom: 20, marginTop: 10, width: '100%'}}>{paymentUrl}</p>

						<Button style={{margin: 5, maxWidth: 400, marginBottom: 25}} appearance='auth' onClick={copyPaymentUrl}>Скопировать</Button>

					</div>

					}

					{paymentUrl === '' &&

					<>

						<div style={{position: 'relative', display: 'flex', width: '90%', flexDirection: 'column'}}>

							<Input style={{width: '100%'}} placeholder='Поиск продукта' textLabel='Поиск продукта' value={searchText} onChange={(event) => setSearchText(event.target.value)}/>

							{searchText.length > 2 && 

						<div style={{position: 'absolute', top: 65, width: '100%', zIndex: 1000, backgroundColor: 'white', maxHeight: 400, overflow: 'auto', borderRadius: 10, boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)'}}>
							{products.map((product) => (
								<>
									<div style={{display: 'flex', alignItems: 'center', paddingLeft: 10, paddingRight: 10}}>
										<img src={product.image_url} style={{width: 50, height: 50, borderRadius: 10, marginRight: 10}}/>
										<div style={{padding: 10, cursor: 'pointer'}} onClick={() => selectProduct(product)}>
											{ product.internal_name === '' ? product.name : product.internal_name}
										</div>
									</div>

									<div className={styles.line} style={{marginBottom: 5, marginTop: 5}}/>
								</>
							))}
						</div>

							}

						</div>


						<div className={styles.line} style={{marginBottom: 10}}/>

						{selectsProducts.length > 0 &&

					<>

						<p style={{marginBottom: 20, marginTop: 10}}><b>Выбранные продукты:</b></p>

						{selectsProducts.map((product, index) => (
							<>
								{index === 0 && <div className={styles.line} style={{marginBottom: 20}}/>}

								<div key={product.id} className={styles.selectedProduct}>

									<div style={{display: 'flex', alignItems: 'center'}}>

										<img src={product.image_url} style={{width: 50, height: 50, borderRadius: 10, marginRight: 5}}/>
										<div style={{padding: 10, fontSize: 14}}>
											{ product.internal_name === '' ? product.name : product.internal_name}
										</div>

									</div>

									<div className={styles.selectedProductPrice}>

										<Input 
											className={styles.selectedProductPriceInput}
											placeholder='0' 
											textLabel='Стоимость в $' 
											type='number' 
											value={product.price} 
											onChange={(event) => {
												const newProduct = selectsProducts.map((item) => {
													if (item.id === product.id) {
														return {
															...item,
															price: Number(event.target.value)
														}
													}
													return item;
												});
												setSelectsProducts(newProduct);
											}}
										/>


										<Close style={{cursor: 'pointer', marginLeft: 10}} onClick={() => {
											const newProduct = selectsProducts.filter((item) => item.id !== product.id);
											setSelectsProducts(newProduct);
										}}/>

									</div>
								</div>

								{index !== selectsProducts.length - 1 && <div className={styles.line} style={{marginBottom: 20}}/>}
							</>
						))}

						<div className={styles.line} style={{marginBottom: 10}}/>
					
					</>

						}

					

						<Button onClick={handleButtonClick} style={{margin: 5, maxWidth: 340, marginBottom: 25}} appearance='auth'>Создать</Button>

					</> }

				</div>
			</div>
		</>
	);
});

export default CreateOrderPopUP;
