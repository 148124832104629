import React from 'react';
import { Routes, Route} from 'react-router-dom';
import { MainContextProvider } from '../context/storeContext';
import { SetPageTitle } from '../components/global/PageTitles/SetPageTitle';
import { Notification } from '../components/global/Notification/Notification';
import Preloader from '../components/global/preloader/Preloader';
import { LoginPage } from '../pages/auth/login';
import RequireAuth from '../hooks/RequireAuth';
import createPropsApiClient from '../context/apiClient';
import { Layout } from '../components/layout/Layout';
import ProfilePage from '../pages/profile/Profile';
import WorkDay from '../pages/workDay/WorkDay';
import MenuMobile from '../pages/menu/Menu';

export const AllRoutes = (): JSX.Element => {
	const apiProps = createPropsApiClient();

	return (
		<MainContextProvider api={apiProps}>
			<SetPageTitle />
			<Notification />
			<Preloader />
			<Routes>
				<Route path="/" element={<RequireAuth><LoginPage /></RequireAuth>} />
				<Route path='/' element={<Layout />}>
					<Route path='/profile' element={<RequireAuth><ProfilePage/></RequireAuth>}/>
					<Route path='/work' element={<RequireAuth><WorkDay/></RequireAuth>}/>
					<Route path='/menu' element={<RequireAuth><MenuMobile/></RequireAuth>}/>
				</Route>
			</Routes>
		</MainContextProvider>
	);
};