/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import styles from './CompleteTask.module.css';
import { ReactComponent as Close } from '../ico/close.svg';
import { Button } from '../../UI/Button/Button';
import CurrentClient from '../../../api/workDay/dto/GetCurrentClientResp';
import { observer } from 'mobx-react';
import { useStores } from '../../../hooks/useStore';
import { useApi } from '../../../hooks/useApi';
import { Input } from '../../UI/input/input';
import DatePickerSingle from '../../UI/DatePicker/datePickerSingle';
import { useEffect, useState } from 'react';
import StagesPipeline from '../../../api/workDay/dto/StagesPipeline';

interface PopupProps {
  client: CurrentClient;
  onClose: () => void;
	updateClient: () => void;
}

const CompleteTaskPopUP: React.FC<PopupProps> = observer(({ client, onClose, updateClient }) => {

	const store = useStores();
	const api = useApi();

	const [result, setResult] = useState<string>('');

	const handleOverlayClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		// Проверяем, что клик произошел именно на overlay, а не на его содержимое
		if (event.target === event.currentTarget) {
			onClose();
		}
	};

	const handleButtonClick = async () => {
		try {
			store.notificationsStore.showPreloader();

			await api.workDay.completeTask({
				task_id: client.task.id,
				task_comment: result,
			});

		} catch (e) {
			store.notificationsStore.setError('Ошибка закрытия задачи');
		} finally {
			updateClient();
			store.notificationsStore.hidePreloader();
			store.notificationsStore.setNotifications('Задача закрыта');
			onClose();
		}
	}
	
	return (
		<>
			<div className={styles.container} onClick={handleOverlayClick}>
				<div className={styles.form}>
					<Close className={styles.close} onClick={onClose}/>
					<h3>Выбери новый этап и задачу</h3>
					<div className={styles.line} style={{marginBottom: 10}}/>

					<Input style={{maxWidth: '90%'}} placeholder='Результат закрытия задачи' textLabel='Результат закрытия задачи' onChange={(event) => setResult(event.target.value)}/>

					
					<div className={styles.line} style={{marginBottom: 10}}/>

					<Button onClick={handleButtonClick} style={{margin: 5, maxWidth: 400, marginBottom: 25}} appearance='auth'>Закрыть задачу</Button>

				</div>
			</div>
		</>
	);
});

export default CompleteTaskPopUP;
