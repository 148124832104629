import moment from 'moment';

export function formatMySQLDatetime(date: Date | undefined, endOfDay = false) {
	if (!date) {return '';}
	const year = date.getFullYear();
	const month = (date.getMonth() + 1).toString().padStart(2, '0');
	const day = date.getDate().toString().padStart(2, '0');

	// Устанавливаем время на 00:00:00
	let formattedDate = `${year}-${month}-${day} 00:00:00`;

	if (endOfDay) {
		// Устанавливаем время на 23:59:59
		formattedDate = `${year}-${month}-${day} 23:59:59`;
	}

	return formattedDate;

	// const time = moment.utc(formattedDate, 'YYYY-MM-DD HH:mm:ss').utcOffset(5);

	// formattedDate = time.format('YYYY-MM-DD HH:mm:ss');

	// return formattedDate;
}

export function isDateExpired(dateString: string): boolean {
	// Преобразуем строку даты в формат ISO 8601
	const parsedDate = dateString.replace(' +0000 UTC', 'Z').replace(' ', 'T');

	// Создаем объект moment из строки
	const date = moment(parsedDate);

	// Проверяем, корректна ли дата
	if (!date.isValid()) {
		return false; // Если дата некорректна, вернем false
	}

	// Сравниваем дату с текущим временем
	return moment().isBefore(date);
}

export function formatDateForTask(dateString: string) {
	// Укажите формат строки даты, чтобы moment.js мог её распознать
	const date = moment(dateString, 'YYYY-MM-DD HH:mm:ss.SSS Z [UTC]');

	if (!date.isValid()) {
		return 'Invalid date';
	}

	return date.format('D MMMM HH:mm');
}