/* eslint-disable @typescript-eslint/no-unused-vars */
import styles from './menu.module.css';
// import { useStores } from '../../hooks/useStore';
import { observer } from 'mobx-react';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';
import { ReactComponent as AutotrackingIco } from '../../components/layout/Sidebar/ico/autotracking.svg';

const MenuMobile: React.FC = observer(() => {
	
	// const {userStore} = useStores();

	const paths = {
		work: '/work',
		profile: '/profile',
	};


	const setActiveLink = (isActive: boolean) => isActive ? cn(styles.menuItem, styles.active) : styles.menuItem;

	return (
		<div>

			<div className={styles.menu}>


				<NavLink to={paths.work} className={({isActive}) => setActiveLink(isActive)}>
					<div className={styles.line}/>
					<AutotrackingIco/>
					<p>Рабочий день</p>
				</NavLink>
				

			</div>

		</div>
	);
});

export default MenuMobile;