// import ApiClient from '../api/ApiClient';
import { AppSettings, EnvEnum, env } from '../appSettings';
import { useCookies } from 'react-cookie';

// const useApiCookies = () => {
// 	const [cookies, setCookie, removeCookie] = useCookies();

// 	const saveToken = (key: string, value: string) => {
// 		console.log(`Setting cookie: ${key} = ${value}`);
// 		setCookie(key, value, { path: '/' });
// 	};

// 	const deleteToken = (key: string) => {
// 		console.log(`Removing cookie: ${key}`);
// 		removeCookie(key, { path: '/' });
// 	};

// 	const getToken = (key: string) => {
// 		console.log(`Getting cookie: ${key}`);
// 		console.log(cookies);
// 		const token = cookies[key];
// 		console.log(`Getting cookie: ${key} = ${token}`);
// 		return token;
// 	};

// 	return { saveToken, deleteToken, getToken };
// };


// const createApiClient = () => {
// 	// const { saveToken, deleteToken, getToken } = useApiCookies();
// 	const [cookies, setCookie, removeCookie] = useCookies();

// 	return new ApiClient({
// 		baseUrl: AppSettings.api.url,
// 		debug: env === EnvEnum.prod,
// 		auth: {
// 			access: {
// 				name: AppSettings.auth.access.name,
// 				durationDays: AppSettings.auth.access.durationDays,
// 			},
// 			refresh: {
// 				name: AppSettings.auth.refresh.name,
// 				durationDays: AppSettings.auth.refresh.durationDays,
// 			},
// 		},
// 		saveToken: (key: string, value: string) => {
// 			setCookie(key, value, { path: '/' });
// 		},
// 		deleteToken: (key: string) => {
// 			removeCookie(key, { path: '/' });
// 		},
// 		getToken: (key: string) => {
// 			const token = cookies[key];
// 			return token;
// 		},
// 	});
// };


// export default createApiClient;

const CreatePropsApiClient = () => {
	const [cookies, setCookie, removeCookie] = useCookies();

	return {
		baseUrl: AppSettings.api.url,
		debug: env === EnvEnum.prod,
		auth: {
			access: {
				name: AppSettings.auth.access.name,
				durationDays: AppSettings.auth.access.durationDays,
			},
			refresh: {
				name: AppSettings.auth.refresh.name,
				durationDays: AppSettings.auth.refresh.durationDays,
			},
		},
		saveToken: (key: string, value: string) => {
			setCookie(key, value, { path: '/' });
		},
		deleteToken: (key: string) => {
			removeCookie(key, { path: '/' });
		},
		getToken: (key: string) => {
			const token = cookies[key];
			return token;
		},
	};
}

export default CreatePropsApiClient;

// export default createPropsApiClient;