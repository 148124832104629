/* eslint-disable no-unused-vars */
import ApiClient from '../ApiClient';
import { NiceResponse } from '../dto/NiceResponse';
import CompleteClientReq from './dto/CompleteClientReq';
import CompleteTask from './dto/CompleteTask';
import { CreateOrderAxl } from './dto/CreateOrder';
import CurrentClient from './dto/GetCurrentClientResp';
import OrdersDeal from './dto/OrdersDeal';
import ProductList from './dto/ProductList';
import RealizedClientReq from './dto/RealizedClientReq';
import StagesPipeline from './dto/StagesPipeline';
import { TaskList } from './dto/TaskList';
import UnrealizedClientReq from './dto/UnrealizedClientReq';


export class WorkDayApi {
	constructor(private api: ApiClient) { }

	path = '/works';

	async getCurrentClient(responsibleId: string, taskId: string | null = null) {

		const data: {responsible_id: string, task_id: string | null} = {
			responsible_id: responsibleId,
			task_id: null
		}

		if (taskId) {
			data['task_id'] = taskId;
		}

		const revenue = await this.api.client.post<NiceResponse<CurrentClient>>(this.path + '/current-client', data);
		return revenue.data;
	}

	async getTaskList(responsibleId: string, skip: number, take: number) {
		const revenue = await this.api.client.get<NiceResponse<TaskList>>(this.path + '/task-list?id=' + responsibleId + '&skip=' + skip + '&take=' + take);
		return revenue.data;
	}

	async unrealizedClient(data: UnrealizedClientReq) {
		const response = await this.api.client.post<NiceResponse<null>>(this.path + '/unrealized-client', data);
		return response.data;
	}

	async realizedClient(data: RealizedClientReq) {
		const response = await this.api.client.post<NiceResponse<null>>(this.path + '/realized-client', data);
		return response.data;
	}

	async completeClientReq(data: CompleteClientReq) {
		const response = await this.api.client.post<NiceResponse<null>>(this.path + '/complete-client', data);
		return response.data;
	}

	async getStagesPipeline(pipelineId: string) {
		const data = await this.api.client.get<NiceResponse<StagesPipeline[]>>(this.path + '/stages-pipeline?pipeline=' + pipelineId);
		return data.data;
	}

	async createOrder(data: CreateOrderAxl) {
		const response = await this.api.client.post<NiceResponse<string>>(this.path + '/create-order', data);
		return response.data;
	}

	async getProductList(text: string) {

		let textUrl = '/products?skip=0&take=50'

		if (text && text !== '') {
			textUrl += '&search=' + text
		}

		const response = await this.api.client.get<NiceResponse<ProductList[]>>(this.path + textUrl);
		return response.data;
	}

	async getOrdersForDeal(dealId: string) {
		const response = await this.api.client.get<NiceResponse<OrdersDeal[]>>(this.path + '/orders-for-deal?id=' + dealId);
		return response.data;
	}

	async completeTask(req: CompleteTask) {
		const response = await this.api.client.post<NiceResponse<null>>(this.path + '/complete-task', req);
		return response.data;
	}

	async deleteOrder(orderId: string) {
		const response = await this.api.client.delete<NiceResponse<null>>(this.path + '/delete-order?id=' + orderId);
		return response.data;
	}
}
