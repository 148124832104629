export function formatNumberWithSpaces(value: number | string): string {
	if (typeof value === 'number') {
		return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
	} else if (typeof value === 'string') {
		const numericValue = parseFloat(value);
		if (!isNaN(numericValue)) {
			return numericValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
		}
	}
	return '';
}