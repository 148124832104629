import styles from './CallTo.module.css';
import { ReactComponent as Close } from '../ico/close.svg';
import { Button } from '../../UI/Button/Button';
import { observer } from 'mobx-react';
import { useStores } from '../../../hooks/useStore';

interface PopupProps {
  phone: string;
  onClose: () => void;
}

const CallToPopUP: React.FC<PopupProps> = observer(({ phone, onClose }) => {

	const store = useStores();

	const handleOverlayClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		// Проверяем, что клик произошел именно на overlay, а не на его содержимое
		if (event.target === event.currentTarget) {
			onClose();
		}
	};

	const callTel = () => {
		window.open('tel:' + phone, '_blank');
		onClose();
	}

	const callSip = () => {
		window.open('sip:' + phone, '_blank');
		onClose();
	}

	const callCallTo = () => {
		window.open('callto:' + phone, '_blank');
		onClose();
	}

	const callZoiper = () => {
		window.open('zoiper://' + phone, '_blank');
		onClose();
	}

	const callLinPhone = () => {
		window.open('sip-linphone:' + phone, '_blank');
		onClose();
	}

	const copyPhone = async () => {
		await navigator.clipboard.writeText(phone);
		store.notificationsStore.setNotifications('Телефон скопирован');
		onClose();
	}
	
	return (
		<>
			<div className={styles.container} onClick={handleOverlayClick}>
				<div className={styles.form}>
					<Close className={styles.close} onClick={onClose}/>
					<h3>Действия с номером телефона</h3>
					<div className={styles.line} style={{marginBottom: 10}}/>
					<div className={styles.buttons}>
						<Button appearance='link' onClick={copyPhone} className={styles.button} style={{margin: 5}}>Скопировать номер</Button>
						<Button appearance='link' onClick={callZoiper} className={styles.button} style={{margin: 5}}>Набрать в Zoiper</Button>
						<Button appearance='link' onClick={callLinPhone} className={styles.button} style={{margin: 5}}>Набрать в LinPhone Mac</Button>
						<Button appearance='link' onClick={callSip} className={styles.button} style={{margin: 5}}>sip</Button>
						<Button appearance='link' onClick={callTel} className={styles.button} style={{margin: 5}}>tel</Button>
						<Button appearance='link' onClick={callCallTo} className={styles.button} style={{margin: 5}}>callto</Button>
					</div>
				</div>
			</div>

		</>
	);
});

export default CallToPopUP;
