import { useEffect } from 'react';
import { observer } from 'mobx-react-lite'; // или 'mobx-react' для MobX до версии 6
import { useLocation, useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useStores } from './useStore'; // Подключите ваш хук для доступа к хранилищам и API
import { useApi } from './useApi'; // Подключите ваш хук для доступа к API
import { AppSettings } from '../appSettings';
// import { User } from '../models/user';

interface RequireAuthProps {
  children: React.ReactNode;
}

const RequireAuth = observer(({ children }: RequireAuthProps): React.ReactElement => {
	const location = useLocation();
	const { userStore } = useStores();
	const api = useApi();
	const navigate = useNavigate();
	const [cookies] = useCookies();

	const authPages = ['/', '/registration', '/reset-password', '/forget-password'];

	useEffect(() => {
		(async () => {
			const isAuthPage = authPages.includes(location.pathname);
			const isAuthenticated = api.isAuthorized();
			const accessCookie = cookies[AppSettings.auth.access.name];
			const refreshCookie = cookies[AppSettings.auth.refresh.name];

			if (accessCookie && refreshCookie && userStore.user === null) {
				api.saveTokens(accessCookie, refreshCookie);
			} else if (isAuthenticated && isAuthPage) {
				navigate('/work');
			} else if (!isAuthenticated && !isAuthPage) {
				navigate('/');
			}
		})();
	}, [userStore]);

	return <>{children}</>;
});

export default RequireAuth;